.searchInput {
  height: 30px;
  border-top-left-radius: 22px;
  border-bottom-left-radius: 22px;
}

.searchBtn {
  padding: 0 0.75rem;
  border-radius: 22px !important;
  left: -10px;
  background-color: #007681;
  background-image: radial-gradient(#009ca9 30%, #007681 75%);
}
