@import "../../variables";

.assignee-exists-alert {
  .MuiPaper-root {
    width: 450px;

    .alert-header {
      color: #FFF;
      background: $PRIMARY_COLOR;
    }

    .alert-title {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 0;
      text-align: center;
      padding: 15px;
    }

    .action-panel {
      justify-content: center !important;

      .btn {
        color: $PRIMARY_COLOR;
      }
    }
  }
}