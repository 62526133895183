.custom-table-row {
  &:hover {
    background-color: rgba(0, 0, 0, 0.07);
  }

  .custom-table-cell {
    max-width: 300px;
  }
}

.parent-data-row {
  td {
    font-weight: bold;
  }
}