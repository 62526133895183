@import '../../variables';

.custom-data-table {
  position: relative;

  .MuiToolbar-regular {
    .MuiButtonBase-root {
      &:hover, &:active, &:focus {
        color: $PRIMARY_COLOR;
      }
    }
  }

  .icon-button {
    &:hover, &:active, &:focus {
      color: $PRIMARY_COLOR;
    }
  }

  .loading-con {
    position: absolute;
    top: 0;
    width: 100%;
    background: #efeff0c2;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;

    .loading-indicator {
      color: #1aa6b1;
    }
  }
}