@import "../../variables";

.max-width-dialog-title {
  background-color: #009ca9;
  color: #ffffff;
}

.time-picker-content {
  padding: 0 10px !important;
}

.apply-btn {
  color: $PRIMARY_COLOR !important;
}