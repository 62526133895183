@import "../../variables";

.footer {
  position: relative;
  bottom: 31px;
  width: fit-content;
  z-index: 101;

  .footer-link {
    text-decoration: none;
    color: $FOOTER_LINKS_COLOR;
    font-size: 13px;
    font-weight: 700;
    padding-left: 20px;
    font-family: 'Open Sans', sans-serif;
    letter-spacing: 0.65px;
    text-transform: uppercase;
    opacity: 1;

    &:hover {
      color: $PRIMARY_COLOR;
    }

    &:first-child {
      padding-right: 20px;
      border-right: 1px solid #414245;
    }
  }
}

