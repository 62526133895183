@import '../../variables';

.alert-console {
  width: 100%;
  flex: 1 0 auto;

  .alert-console-tabs-con {
    background: #efeff0;

    .alert-console-tabs {
      min-height: calc(#{$HEADER_HEIGHT} / 2);

      .MuiTab-root {
        min-height: calc(#{$HEADER_HEIGHT} / 2);
      }
    }
  }

  .alerts-container {
    width: inherit;

    .alert-console-search-con {
      padding: 10px 15px;
      border-radius: 0;

      .alert-console-search-input {
        width: calc(100% - 50px);
        border: 1px solid #ddd;
        border-radius: 20px;
        padding: 0 20px;
      }

      .alert-console-search-btn {
        color: $PRIMARY_COLOR;
      }
    }
  }
}

.alert-timestamp {
  width: 120px;
}

.alert-message-cell {
  margin: 0;
  overflow: hidden;
  overflow-wrap: break-word;
  word-wrap: break-word;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.alert-current-status {
  width: 95px;
  display: flex;
  align-items: center;
  margin: 0;

  .alert-status-in-progress-icon {
    font-size: 18px;
    color: $PRIMARY_COLOR;
  }

  .alert-status-new-icon {
    font-size: 18px;
    color: #f7b800;
  }
}

.alert-severity {
  width: 95px;
  display: flex;
  align-items: center;
  margin: 0;
  font-weight: 500;
  overflow: hidden;
  overflow-wrap: break-word;
  word-wrap: break-word;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;

  .alert-severity-warning-icon {
    font-size: 18px;
    color: #f73f00;
  }
}

.elapsed-time {
  width: 130px;
}

.warning-time {
  color: #ff0000;
  font-weight: 500;
}

.alert-last-status-changed {
  width: 120px;
}

.create-incident-btn {
  padding: 0 8px !important;
  text-transform: capitalize !important;
  border-radius: 20px !important;
  color: $PRIMARY_COLOR !important;
  border-color: #c7c7c7 !important;
}

.action-type {
  width: 95px;
  display: flex;
  align-items: center;
  margin: 0;

  .self-healed {
    font-size: 18px;
    color: #55d446;
  }
}

.associated-event-link {
  text-decoration: none;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }
}

.associated-event-dynatrace-link, .associated-event-solarwinds-link {
  text-decoration: none;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }
}

.current-agent-option {
  display: grid;
  text-align: start;
  min-width: 95px;

  .agent-name {
    margin-bottom: 2px;
  }

  .agent-action-link {
    text-decoration: none;
    font-weight: bold;

    &:hover {
      text-decoration: underline;
    }
  }
}