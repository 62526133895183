.main-layout {
    margin: 0 !important;
    width: 100% !important;
    display: flex;
    flex-direction: row;
    height: 100vh;

    .root-item {
        height: inherit;
        display: flex;
        flex-direction: column;
        width: 100%;
        background: white;
    }
}