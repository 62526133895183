@import '../../variables';

.header {
  height: $HEADER_HEIGHT;
  width: 100%;
  display: flex;
  z-index: 10;
  box-shadow: 0 2px 10px -4px #c5c5c5;

  .left-con {
    width: $HEADER_LEFT_CON_WIDTH;
    height: inherit;
    background-color: $HEADER_LEFT_CON_COLOR;
    display: flex;
    align-items: center;
    padding-left: 20px;
    box-shadow: 10px 0 10px -4px #d0d0d0;
    border-right: 5px solid #88d0d6;

    .logo {
      height: 50px;
      margin-right: 20px;
    }

    .title {
      font-size: 24px;
      font-weight: 500;
      font-family: 'Titillium Web', sans-serif;
      color: #FFF;
    }
  }

  .center-con {
    width: calc(100% - #{$HEADER_LEFT_CON_WIDTH} - #{$HEADER_RIGHT_CON_WIDTH});
    border-right: 1px solid #c5c5c5;

    .section-one {
      height: calc(#{$HEADER_HEIGHT} / 2);
      border-bottom: 1px solid #c5c5c5;

      .inner-one {
        padding: 9px 25px;
        display: flex;

        .logo-con {
          margin-right: 15px;

          .wiley-logo {
            width: 60px;
          }
        }

        .system-clock {

        }
      }

      .inner-two {
        padding: 7px;
        display: flex;
        justify-content: flex-end;

        .time-range-picker-con {
          margin-right: 35px;

          .time-range-picker {
            padding: 0 8px;
            font-size: 11px;
            border-radius: 10px;
          }
        }

        .auto-refresh-con {
          .auto-refresh-label {
            .MuiFormControlLabel-label {
              font-size: 14px;
              margin-left: 10px;
            }
          }
        }
      }
    }

    .section-two {
      height: calc(#{$HEADER_HEIGHT} / 2);

      .nav-panel {
        min-height: calc(#{$HEADER_HEIGHT} / 2);

        .MuiTab-root {
          min-height: calc(#{$HEADER_HEIGHT} / 2);
        }
      }
    }
  }

  .right-con {
    width: $HEADER_RIGHT_CON_WIDTH;
    display: flex;
    justify-content: center;

    .avatar-button {
      .avatar-icon {
        margin: 6px;
        width: 45px;
        height: 45px;
        background-color: #1aa6b1
      }
    }
  }
}

.auto-refresh-snackbar {
  .MuiSnackbarContent-message {
    width: 100%;
    text-align: center;
  }
}